import React, { useState } from "react"
import NewLayout from "../components/new_layout"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import CardPost from "../components/v2023/UI/Card/card-post"
import BlogCta from "../components/v2023/UI/Blog/blog-cta"

const Sector = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const sector = data.sector
    const images = pageContext.images

    const pathBlog = lang === "en" ? "/blog" : `/${lang}/blog`
    const pathWhitePapers = lang === "en" ? "/ebooks-whitepapers" : `/${lang}/ebooks-whitepapers`
    const pathGeneric = lang === "en" ? "/" : "/es"

    const [selectedCustomerStoryIndex, setSelectedCustomerStoryIndex] = useState(0)
    const [nextCustomerStoryAvailable, setNextCustomerStoryAvailable] = useState(true)
    const [prevCustomerStoryAvailable, setPrevCustomerStoryAvailable] = useState(true)

    const selectCustomerStoryIndex = (index) => {
        setSelectedCustomerStoryIndex(index)
        //setPrevCustomerStoryAvailable(index > 0)
        //setNextCustomerStoryAvailable((CustomerStorys.length - 1) > index)
    }

    const nextCustomerStory = () => {
        if (selectedCustomerStoryIndex === (sector.customer_stories_items.length - 1)) {
            setSelectedCustomerStoryIndex(0)
            return
        }

        setSelectedCustomerStoryIndex(selectedCustomerStoryIndex + 1)
    }

    const prevCustomerStory = () => {
        if (selectedCustomerStoryIndex === 0) {
            setSelectedCustomerStoryIndex(sector.customer_stories_items.length - 1)
            return
        }

        setSelectedCustomerStoryIndex(selectedCustomerStoryIndex - 1)
    }

    const getHighlightedResourceData = (item) => {
        if (item.strapi_component === "sector.blog") {
            return {
                title: item.title,
                url: `${pathBlog}/${item.blog_post.url}`,
                tag: "Blog",
                image: { localFile: images[item.image.localFile___NODE].data.image }
            }
        }

        if (item.strapi_component === "sector.e-book") {
            return {
                title: item.title,
                url: `${pathWhitePapers}/${item.ebook.url}`,
                tag: "eBook",
                image: { localFile: images[item.image.localFile___NODE].data.image }
            }
        }

        return {
            title: item.title,
            url: `${pathGeneric}/${item.generic_landing_page.url}`,
            tag: lang === "en" ? "Use Case" : "Caso de uso",
            image: { localFile: images[item.image.localFile___NODE].data.image }
        }
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={sector.seo.title}
                description={sector.seo.meta_description}
                image={sector.seo?.image_2?.localFile?.publicURL}
                translates={pageContext.translates}
            />

            <main className="main">
                <div className="main__section main__section--50 white-section white">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag tag--highlight">{sector.tag}</div>

                            <div className="container__text">
                                <h1 className="merriweather">{sector.title}</h1>
                            </div>

                            <ReactMarkdown children={sector.description} rehypePlugins={[rehypeRaw]} />

                            <LinkButton button={sector.button} className="button button--xl" />
                        </div>

                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={sector.hero_image.alternativeText} image={getImage(sector.hero_image.localFile)} />
                        </div>
                    </div>
                </div>

                <div className="main__section">
                    <div className="container container--np">
                        <div className="grid-md">
                            {sector.logos.map((logo, index) => {
                                return <GatsbyImage
                                    key={index}
                                    image={getImage(logo.image.localFile)}
                                    alt={logo.alt}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{sector.agility_title}</h2>

                        <div className="container__text">
                            <ReactMarkdown children={sector.agility_description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <div className="grid-lg-3-h">
                            {sector.agility_items.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="image-group" key={index} {...animation}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>

                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                {sector.opinion && <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--white--nb">
                            <i className="icon-blockquote i-xl"></i>
                            <div className="tile__body">
                                <div className="fs-24">
                                    "{sector.opinion.message}"
                                </div>
                            </div>
                            <div className="avatar">
                                {sector.opinion.photo &&
                                    <div className="avatar__photo">
                                        <GatsbyImage alt={sector.opinion.photo.alternativeText} image={getImage(sector.opinion.photo.localFile)} />
                                    </div>
                                }
                                <div className="avatar__job">
                                    <b>{sector.opinion.name}</b>
                                    <span>{sector.opinion.job_position}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{sector.customer_hub_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={sector.customer_hub_description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <div className="grid-lg-2 ai-center">
                            <div>
                                <GatsbyImage alt={sector.customer_hub_image.alternativeText} image={getImage(sector.customer_hub_image.localFile)} />
                            </div>
                            <div className="container__text">
                                {sector.customer_hub_characteristics_items.map((item, index) => {
                                    return <div className="container__text__section"key={index}>
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                })}
                                <LinkButton button={sector.customer_hub_button} className={"link--big"} extras={<i className="icon-arrow-right"></i>} />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <BlogCta data={sector.cta} />
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{sector.warranties_title}</h2>

                        <div className="container__text">
                            <ReactMarkdown children={sector.warranties_description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <div className="grid-lg-3-h">
                            {sector.warranties_items.map((item, index) => {
                                return <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>

                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        <LinkButton button={item.button} className="link--big" extras={<i className="icon-arrow-right"></i>} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100 sector-carrousel">
                    <div className="container">
                        <div className="container__text">
                            <h2>{sector.customer_stories_title}</h2>
                        </div>

                        <div className="carrousel">
                            <div className="carrousel__content">
                                <div className="carrousel__content__button">
                                    <button className="button-icon button-icon--secondary button-icon--lg" disabled={!prevCustomerStoryAvailable} onClick={prevCustomerStory}>
                                        <i className="icon-arrow-left"></i>
                                    </button>
                                </div>

                                <div className="carrousel__content__box">
                                    {sector.customer_stories_items.map((item, index) => {
                                        return <CardPost
                                            key={`white-paper-${index}`}
                                            lang={lang}
                                            name={item.title}
                                            image={item.image}
                                            url={item.case_study.url}
                                            description={item.description}
                                            horizontal={true}
                                            showMore={true}
                                            customClass={selectedCustomerStoryIndex === index ? "card--post--horizontal--show" : null} />
                                    })}
                                </div>

                                <div className="carrousel__content__button">
                                    <button className="button-icon button-icon--secondary button-icon--lg" disabled={!nextCustomerStoryAvailable} onClick={nextCustomerStory}>
                                        <i className="icon-arrow-right"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="carrousel__dots">
                                {sector.customer_stories_items.map((item, index) => {
                                    return <span className={`dots__dot ${selectedCustomerStoryIndex === index ? "dots__dot--active" : ""}`} key={index}
                                                 onClick={() => selectCustomerStoryIndex(index)} />
                                })}
                            </div>
                        </div>

                        <LinkButton button={sector.customer_stories_button} className="button button--xl" />
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{sector.highlighted_resources_title}</h2>

                        <div className="grid-lg-3-h">
                            {sector.highlighted_resources_items.map((item, index) => {
                                let dataItem = getHighlightedResourceData(item)

                                console.log("highlighted_resources_items", dataItem)

                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    tagHighlight={dataItem.tag}
                                    url={dataItem.url}
                                    image={dataItem.image}
                                    name={dataItem.title}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />
            </main>

        </NewLayout>
    )
}

export default Sector

export const sectorQuery = graphql`
    query ($id: String!, $langKey: String!)  {
        sector: strapiSector (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
            }
            tag
            title
            description
            button {
                text
                url
                blank
            }
            hero_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 656
                            formats: [WEBP]
                            quality: 100
                        )
                    }
                }
            }
            logos {
                alt
                title
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 120
                                width: 120
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            agility_title
            agility_description
            agility_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 80
                                width: 80
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            opinion {
                name
                job_position
                message
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 64
                                width: 64
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            customer_hub_title
            customer_hub_description
            customer_hub_characteristics_items{
                title
                description
            }
            customer_hub_button {
                text
                url
                blank
            }
            customer_hub_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 668
                            formats: [WEBP]
                            quality: 100
                        )
                    }
                }
            }
            cta {
                name
                description
                button
                url
                blank
            }
            warranties_title
            warranties_description
            warranties_items {
                title
                description
                button {
                    text
                    url
                    blank
                }
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 430
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            customer_stories_title
            customer_stories_items {
                title
                description
                button
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 910
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                case_study {
                    url
                }
            }
            customer_stories_button {
                text
                url
                blank
            }
            highlighted_resources_title
            highlighted_resources_items
        }
    }
`
